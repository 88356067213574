<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card
          v-show="reportListComputed.length"
          class="table-card mt-4"
        >
          <v-data-table
            ref="reportTable"
            class="base-table"
            fixed-header
            hide-default-header
            hide-default-footer
            :headers="headers"
            :items="reportListComputed"
            :items-per-page="-1"
          >
            <template #header="{ props }">
              <thead>
                <tr>
                  <th class="text-center" rowspan="2">{{ firstColumnName }}</th>
                  <th class="text-center" colspan="3">Количество циклов</th>
                  <th class="text-center" rowspan="2">Сумма баллов</th>
                </tr>

                <tr>
                  <th class="text-center">< 10</th>
                  <th class="text-center">> 10</th>
                  <th class="text-center">> 25</th>
                </tr>
              </thead>
            </template>

            <template slot="no-data">
              <div>Таблица пуста</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReportsTable',

  props: {
    paramsBlockHeight: Number,
    reportList: Array,
    tableType: String
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  computed: {
    ...mapGetters('system', ['techMessageHeight']),

    headers() {
      return [
        { value: this.firstColumnValueKey, sortable: false, align: 'left' },
        { value: 'sumOfLessOrEquals10', sortable: false, align: 'center' },
        { value: 'sumOfMoreThen10AndLessThen25', sortable: false, align: 'center' },
        { value: 'sumOfMoreOrEquals25', sortable: false, align: 'center' },
        { value: 'sumStaff', sortable: false, align: 'center' }
      ]
    },

    firstColumnName() {
      switch(this.tableType) {
        case 'curatorDepartment': return 'ФИО куратора цикла'
        case 'curatorFaculty': return 'Наименование кафедры'
      }
    },

    firstColumnValueKey() {
      switch(this.tableType) {
        case 'curatorDepartment': return 'fullName'
        case 'curatorFaculty': return 'deptName'
      }
    },

    reportListComputed() {
      return this.reportList.map(item => {
        return {
          ...item,
          [this.firstColumnValueKey]: item[this.firstColumnValueKey] || '-'
        }
      })
    }
  },

  methods: {
    setTableHeight() {
      const otherElements = 295 + this.paramsBlockHeight + this.techMessageHeight

      if (this.$refs?.reportTable) {
        this.$refs.reportTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    }
  },

  watch: {
    paramsBlockHeight() {
      this.setTableHeight()
    }
  }
}
</script>

<style scoped>
.base-table thead th {
  /* стили в шапке таблицы */
  white-space: nowrap;
  color: var(--accent-color) !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  height: 32px !important;
}

.base-table >>> thead tr:last-child th {
  top: 49px !important
}
</style>